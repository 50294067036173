import React from 'react'
import FAQ from '../../organisms/FAQ';
import Footer from '../../organisms/Footer';

import Hero from '../../organisms/Hero'
import JoinUs from '../../organisms/JoinUs';
import NavBar from '../../organisms/NavBar';
import Tech from '../../organisms/Tech';

const Main : React.FC = () => {
  return (
    <React.Fragment>
      <NavBar />
      <Hero />
      <FAQ />
      <Tech />
      <JoinUs />
      <Footer />
    </React.Fragment>
  )
}

export default Main;
