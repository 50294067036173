import React from 'react'

import { createStyles, makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import Container from '../../atoms/Container';

const useStyles = makeStyles(() => createStyles({
  footer: {
    padding: '1.5rem',
    backgroundColor: '#f2f5fa',
    width: '100%',
    overflow: 'hidden',
    fontSize: '14px',
  },
  p: {
    fontFamily: '"Open Sans", sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    color: '#47536e',
    textAlign: 'center',
  },
}));

const Footer : React.FC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container>
        <Typography className={classes.p}>
          © Copyright <strong>Schedulty</strong>. All rights reserved.
        </Typography>
      </Container>
    </footer>
  )
}

export default Footer;