import React from 'react';

import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import { useTranslation } from 'react-i18next';

import Card from '../../atoms/Card';
import Container from '../../atoms/Container';

const useStyles = makeStyles(() => createStyles({
  section: {
    backgroundColor: '#f2f5fa',
  },
  h1: {
    "@media (max-width: 991px)": {
      marginBottom: '16px',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    padding: '0 16px 0 16px',
    "@media (max-width: 991px)": {
      justifyContent: 'center',
    },
  },
  img: {
    width: '64px',
  },
}));

interface Technology {
  name: string;
  img: string;
}

const Tech : React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  const technologies: Technology[] = [
    {
      name: 'Docker',
      img: '/assets/img/brands_icons/docker.svg',
    },
    {
      name: 'NestJS',
      img: '/assets/img/brands_icons/nestjs.svg',
    },
    {
      name: 'PostgreSQL',
      img: '/assets/img/brands_icons/postgresql.svg',
    },
    {
      name: 'Redis',
      img: '/assets/img/brands_icons/redis.svg',
    },
    {
      name: 'Nats',
      img: '/assets/img/brands_icons/nats.png',
    },
    {
      name: 'Gitlab CI',
      img: '/assets/img/brands_icons/gitlab.svg',
    },
    {
      name: 'TypeORM',
      img: '/assets/img/brands_icons/typeorm.png',
    },
    {
      name: 'ReactJS',
      img: '/assets/img/brands_icons/reactjs.svg',
    },
    {
      name: 'Vue.js',
      img: '/assets/img/brands_icons/vue.svg',
    },
    {
      name: 'Traefik',
      img: '/assets/img/brands_icons/traefik.svg',
    },
    {
      name: 'Yandex Cloud',
      img: '/assets/img/brands_icons/yandexcloud.svg',
    },
    {
      name: 'Puppeteer',
      img: '/assets/img/brands_icons/puppeteer.svg',
    },
  ]

  return (
    <section className={classes.section} id={t('navbar.links.1.href').substring(1)}>
      <Container maxWidth={false}>
        <h1 className={classes.h1}>
          {t('tech.title')}
        </h1>
        <Box className={classes.box}>
          {technologies.map(tech => (
            <Card
              key={tech.img + tech.name}
              title={
                <img className={classes.img} title={tech.name} alt={tech.name} src={tech.img} />
              } 
              text={tech.name} 
              height={175}
            />
          ))}
        </Box>
      </Container>
    </section>
  );
}

export default Tech;
