import React, { useState } from 'react';

import { 
  Drawer as MuiDrawer, 
  IconButton, 
  List, 
  Box, 
  ListItem, 
  ListItemText, 
  Button, 
  Divider 
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { createStyles, makeStyles } from '@material-ui/styles';

import { useTranslation } from 'react-i18next';

import Link from '../../molecules/Link';
import { NavLink } from '../../organisms/NavBar';

const useStyles = makeStyles(() => createStyles({
  listItem: {
    width: '100%',
    color: '#47536e',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      color: '#5777ba',
    },
    '&.MuiButtonBase-root': {
      textTransform: 'none',
      padding: 0,
    }
  },
}));

interface DrawerProps {
  links: NavLink[];
  changeLanguage: (event: React.SyntheticEvent) => void;
}

const Drawer : React.FC<DrawerProps> = (props: DrawerProps) => {
  const classes = useStyles();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  
  const { t } = useTranslation();

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        sx={{ ...(isDrawerOpen && { display: 'none' }) }}
      >
        <MenuIcon />
      </IconButton>
      <MuiDrawer
        anchor='right'
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(!isDrawerOpen)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          onKeyDown={() => setIsDrawerOpen(!isDrawerOpen)}
        >
          <List>
            {props.links.map(link => (
              <Link 
                key={link.href + link.text}
                href={link.href}
                isExternal={link.isExternal}
                className={classes.listItem}
              >
                <ListItem button>
                  <ListItemText>
                    {link.text}
                  </ListItemText>
                </ListItem>
              </Link>
            ))}
            <Button
              className={classes.listItem}
              href='https://github.com/Schedulty'
              target='_blank'
            >
              <ListItem>
                <ListItemText>
                  GitHub
                </ListItemText>
              </ListItem>
            </Button>
            <Divider />
            <Button
              className={classes.listItem}
              onClick={props.changeLanguage}
            >
              <ListItem>
                <ListItemText>
                  {t('navbar.changelanguage')}
                </ListItemText>
              </ListItem>
            </Button>
          </List>
        </Box>
      </MuiDrawer>
    </>
  )
}

export default Drawer;