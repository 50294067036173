import React from 'react';

import { 
  Box, 
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => createStyles({
  box: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    color: '#515f7d',
    border: '1px solid #ddd',
    borderRadius: '16px',
    backgroundColor: '#fdfcff',
    margin: '16px 64px',
    "@media (max-width: 991px)": {
      margin: '16px',
    },
  },
  h1: {
    margin: 0,
    fontSize: '3rem',
  },
  h2: {    
    margin: 0,
    fontSize: "1.25rem",
  }
}));

interface CardProps {
  title: any;
  text: any;
  height?: number;
  width?: number;
}

const Card: React.FC<CardProps> = (props: CardProps) => {
  const classes = useStyles();

  return (
    <Box 
      className={classes.box}
      height={`${props.height ?? 150}px`}
      width={`${props.width ?? 250}px`}
    >
      <h1 className={classes.h1}>
        {props.title}
      </h1>
      <h2 className={classes.h2}>
        {props.text}
      </h2>
    </Box>
  )
}

export default Card;
