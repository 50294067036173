import { Container as MuiContainer } from '@material-ui/core';
import { withStyles } from "@material-ui/styles";

const Container = withStyles({
  root: {
    height: '100%',
    maxWidth: '1320px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',    
    alignItems: 'center',
  },
})(MuiContainer);

export default Container;