const en = {
  translation: {
    navbar: {
      links: [
        {
          text: 'F.A.Q.',
          href: '#faq',
        },
        {
          text: 'Technologies',
          href: '#tech',
        },
        {
          text: 'Join Us',
          href: '#join',
        },
        {
          text: 'API',
          href: 'https://api.schedulty.ru/docs',
          isExternal: true,
        },
      ],
      changelanguage: 'Change Language'
    },
    hero: {
      title: 'Schedulty Bot',
      text: "Your university's schedule right in VK or Telegram!",
    },
    counters: {
      universities: 'Universities',
      users: 'Users',
      messages: 'Messages',
    },
    faq: {
      title: 'Frequently Asked Questions',
      text: 'Stuck on a particular problem? Check out some of these common problems first in the FAQ.',
      list: [
        {
          title: 'Everything is broken, the bot does not respond, what should I do?',
          text: 'In most cases, the "Отмена" command will help fix this issue.',
        },
        {
          title: 'Can I get help in the bot itself?',
          text: 'Yes, you can use the "Справка" command in the bot. You either have to write it yourself or click on the button, which is always available.',
        },
        {
          title: 'Can I get the schedule two days in advance or even later?',
          text: 'Yes, our bot is able to show the schedule not only for today and tomorrow. You can build a query according to this format: Расписание [на] [сегодня|завтра|через N дней/недель] | Показать рассписание. Examples:',
          list: [
            'Расписание',
            'Расписание на сегодня',
            'Расписание на завтра',
            'Расписание через 5 дней',
            'Расписание через 10 недель',
            'Расписание на вторник',
            'Расписание на 25.09',
          ]
        },
        {
          title: 'Can I use voice messages in your bot?',
          text: 'Yes, our bot can handle and respond to voice messages. Requests should be constructed in the same way as described in the paragraph above.',
        },
        {
          title: 'Your bot is only in the "Name of the social network"?',
          text: 'You can get a list of all the social networks in which our bot is available by writing the "Ссылки" command.'
        },
        {
          title: 'My school is not in the bot, what can I do?',
          text: 'Unfortunately, we cannot add all universities at once, but you can help us add your organization by leaving an application on the main page.',
        }
      ]
    },
    tech: {
      title: 'Technologies we use',
    },
    joinus: {
      title: 'Join Us',
      text: 'Want easy access to the schedule for your university as well? Then you can join us by using the form below!',
      city: 'City',
      univercity: 'Univercity',
      contact: 'Your email or link to any social network',
      button: 'Join',
      thanks: {
        title: 'Thank you for contacting us!',
        text: 'We will respond to your application as quickly as possible!'
      },
    },
    404: {
      text: 'This page could not be found.',
      home: 'Go back.',
    }
  },
};

export default en;
