import React from 'react'

import { createStyles, makeStyles } from '@material-ui/styles';

import { Box, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => createStyles({
  section: {
    flexDirection: 'row',
    minHeight: '100vh',
    justifyContent: 'center',
    '& h1, h2': {
      margin: 0,
    },
    "@media (max-width: 991px)": {
      flexDirection: 'column',
      '& h1': {
        marginBottom: '16px',
      },
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    textDecoration: 'none',
    fontSize: '16px',
  },
  divider: {
    height: '64px',
    margin: '0 32px',
    "@media (max-width: 991px)": {
      display: 'none',
    },
  },
}));

const Page404: React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <section className={classes.section}>
      <h1>404</h1>
      <Divider className={classes.divider} orientation='vertical' />
      <Box className={classes.box}>
        <h2>
          {t('404.text')}
        </h2>
        <h2>
          <Link to='/' className={classes.link}>{t('404.home')}</Link>
        </h2>
      </Box>
    </section>
  )
}

export default Page404;
