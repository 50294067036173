import React from 'react';

import Card from '../Card';
import CountUp from 'react-countup';

interface CounterProps {
  title: string;
  value: number;
  duration: number;
}

const Counter: React.FC<CounterProps> = (props: CounterProps) => {
  return (
    <Card text={props.title} title={<CountUp end={props.value} duration={props.duration}/>}/>
  )
}

export default Counter;