import React, { useState } from 'react';

import { Box, TextField, Button, CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import Container from '../../atoms/Container';

const useStyles = makeStyles(() => createStyles({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '4ch',
  },
  fields: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '2ch',
    width: '100%',
    '& .MuiTextField-root': {
      width: '50ch',
      margin: '8px',
      "@media (max-width: 991px)": {
        width: '100%',
      },
    },
  },
  button: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    margin: 'auto',
    top: 0, 
    left: 0, 
    bottom: 0, 
    right: 0,
    maxWidth: '24px',
    maxHeight: '24px',
    color: '#515f7d',
  },
  icon: {
    color: '#515f7d',
    minWidth: '250px',
    minHeight: '250px',
  }
}));

interface JoinUsForm {
  city: string;
  title: string;
  contact: string;
}

const formDefaultValues: JoinUsForm = {
  city: '',
  title: '',
  contact: '',
};

const JoinUs : React.FC = () => {
  const classes = useStyles();

  const [ formValues, setFormValues ] = useState(formDefaultValues);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isJoinUsSended, setIsJoinUsSended ] = useState(false);

  const { t } = useTranslation();

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    
    setFormValues({
      ...formValues,
      [name]: value
    });
  }  

  const onSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    setIsLoading(true);
    
    await fetch('https://api.schedulty.ru/public/join', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formValues)
    });
    
    setIsJoinUsSended(true);
  }

  if (isJoinUsSended) {
    return (
      <section id={t('navbar.links.2.href').substring(1)}>
        <Container>
          <h1>
            {t('joinus.thanks.title')}
          </h1>
          <h2>
            {t('joinus.thanks.text')}
          </h2>
          <CheckCircleOutlineIcon className={classes.icon} />
        </Container>
      </section>
    )
  }

  return (
    <section id={t('navbar.links.2.href').substring(1)}>
      <Container>
        <h1>
          {t('joinus.title')}
        </h1>
        <h2>
          {t('joinus.text')}
        </h2>
        <Box 
          className={classes.form} 
          component='form'
          onSubmit={onSubmit}
        >
          <Box className={classes.fields}>
            <TextField
              required
              name='city'
              label={t('joinus.city')}
              value={formValues.city}
              onChange={handleInputChange}
              disabled={isLoading}
            />
            <TextField
              required
              name='title'
              label={t('joinus.univercity')}
              value={formValues.title}
              onChange={handleInputChange}
              disabled={isLoading}
            />
            <TextField
              required
              name='contact'
              label={t('joinus.contact')}
              value={formValues.contact}
              onChange={handleInputChange}
              disabled={isLoading}
            />
          </Box>
          <Box className={classes.button}>
            <Button 
              variant="outlined" 
              color="primary" 
              type="submit"
              sx={{ minWidth: '16ch', borderRadius: '60px' }}
              disabled={isLoading}
            >
              {t('joinus.button')}
            </Button>
            {isLoading && (
              <CircularProgress className={classes.progress} />
            )}
          </Box>
        </Box>
      </Container>
    </section>
  )
}

export default JoinUs;