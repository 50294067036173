import React from 'react';

import { Button as MuiButton, Link as MuiLink } from '@material-ui/core';

interface LinkProps {
  children?: React.ReactNode;
  href: string;
  isExternal: boolean;
  className?: string;
}

const Link : React.FC<LinkProps> = (props: LinkProps) => {

  if (props.isExternal) {
    <MuiButton
      className={props.className}
      href={props.href}
      disableRipple={true}
    >
      {props.children}
    </MuiButton>
  }
  
  return (
    <div>
      <MuiLink 
        className={props.className}
        href={props.href}
      >
        {props.children}
      </MuiLink>
    </div>
  )
}

export default Link;
