import React from 'react';

import {
  Box,
  Button,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import TelegramIcon from '@material-ui/icons/Telegram';
import { useTranslation } from 'react-i18next';

import Container from '../../atoms/Container';
import Counters from '../../molecules/Counters';

const useStyles = makeStyles(() => createStyles({
  section: {
    minHeight: '100vh',
    position: 'relative',
    paddingTop: '140px',
    '&:before': {
      content: '""',
      position: 'absolute',
      right: '-100%',
      top: '20%',
      width: '250%',
      height: '200%',
      zIndex: '-1',
      backgroundColor: '#e8ecf5',
      transform: 'skewY(135deg)',
    },
  },
  container: {
    alignItems: 'unset',
  },
  row: {
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    "@media (max-width: 991px)": {
      flexDirection: 'column-reverse',
      textAlign: 'center',
    },
  },
  h1: {
    textAlign: 'unset',
  },
  h2: {
    textAlign: 'unset',
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    padding: '0 16px 0 16px',
    "@media (max-width: 991px)": {
      justifyContent: 'center',
      width: '100%',
      height: '50%',
    },
  },
  desc: {
    "@media (max-width: 991px)": {
      paddingTop: '32px',
      alignItems: 'center',
    },
  },
  buttons: {
    display: 'flex',
    marginTop: '50px',
  },
  downloadBtn: {
    fontFamily: '"Raleway", sans-serif',
    fontWeight: 500,
    fontSize: '15px',
    textTransform: 'none',
    background: '#47536e',
    width: '160px',
    '&:first-child': {
      marginRight: '21px',
    },
    '&:hover': {
      background: '#5777ba',
    },
  },
  icon: {
    marginRight: '8px',
  },
  image: {
    "@media (max-width: 991px)": {
      maxWidth: 'initial',
    },
  },
}));

const Hero : React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <section className={classes.section} id='schedulty'>
      <Container className={classes.container} maxWidth={false}>
        <Box className={classes.row}>
          <Box className={`${classes.box} ${classes.desc}`}>
            <h1 className={classes.h1}>
              {t('hero.title')}
            </h1>
            <h2 className={classes.h2}>
              {t('hero.text')}
            </h2>
            <Box className={classes.buttons}>
              <Button className={classes.downloadBtn} variant="contained" href='https://vk.me/schedulty_bot' target="_blank">
                <img className={classes.icon} src='\assets\img\vk.svg' alt='vk' />
                VK
              </Button>
              <Button className={classes.downloadBtn} variant="contained" href='https://t.me/schedulty_bot' target="_blank">
                <TelegramIcon className={classes.icon} />Telegram
              </Button>
            </Box>
          </Box>
          <Box className={classes.box} display='flex'>
            <img className={classes.image} title='hero' src='\assets\img\hero.png' alt='Hero' />
          </Box>
        </Box>
        <Box>
          <Counters />
        </Box>
      </Container>
    </section>
  )
}

export default Hero;
