import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Box, CssBaseline } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Main from './components/pages/Main';
import Page404 from './components/pages/404';

const App : React.FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'ru');
      i18n.changeLanguage('ru');
    } else {
      const lang = localStorage.getItem('lang');
      if (lang) {
        i18n.changeLanguage(lang);
      }
    }
  }, [i18n])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CssBaseline />
      <Switch>
        <Route exact path="/" component={Main} />
        <Route path="*" component={Page404} /> 
      </Switch>
    </Box>
  );
}

export default App;
