import React, { useEffect, useState } from 'react';

import {
  Box,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import Counter from '../../atoms/Counter';

const useStyles = makeStyles(() => createStyles({
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '60px',
    flexWrap: 'wrap',
  },
}));

interface ICounters {
  users: number;
  universities: number;
  messages: number;
}

const Counters: React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslation();
  
  const [counters, setCounters] = useState<ICounters>({ users: 0, universities: 0, messages: 0 })

  useEffect(() => {
    const counterLink = 'https://api.schedulty.ru/public/counters';
    
    (async () => {
      const response = await (await fetch(counterLink)).json() as ICounters;
      
      setCounters(response);
    })()
  },[])

  return (
    <Box className={classes.box}>
      <Counter title={t('counters.universities')} value={counters.universities} duration={1} />
      <Counter title={t('counters.users')} value={counters.users} duration={1} />
      <Counter title={t('counters.messages')} value={counters.messages} duration={1} />
    </Box>
  )
}

export default Counters;
