const ru = {
  translation: {
    navbar: {
      links: [
        {
          text: 'F.A.Q.',
          href: '#faq',
        },
        {
          text: 'Технологии',
          href: '#tech',
        },
        {
          text: 'Присоединиться',
          href: '#join',
        },
        {
          text: 'API',
          href: 'https://api.schedulty.ru/docs',
          isExternal: true,
        },
      ],
      changelanguage: 'Сменить язык'
    },
    hero: {
      title: 'Schedulty Bot',
      text: 'Узнавай расписание своего ВУЗа прямо в VK или Telegram!',
    },
    counters: {
      universities: 'Университетов',
      users: 'Пользователей',
      messages: 'Сообщений',
    },
    faq: {
      title: 'Часто задаваемые вопросы',
      text: 'Застряли на конкретной проблеме? Ознакомьтесь сначала с некоторыми из этих распространенных проблем в данном разделе.',
      list: [
        {
          title: 'У меня все сломалось, бот не отвечает, что делать?',
          text: 'В преимущественном количестве случаев команда "Отмена" поможет исправить эту проблему.',
        },
        {
          title: 'Могу ли я получить помощь в самом боте?',
          text: 'Да, вы можете использовать команду "Справка" в боте. Вам нужно либо написать ее самостоятельно, либо нажать на кнопку, которая доступна всегда.',
        },
        {
          title: 'Могу ли я получить расписание на два дня вперед или на еще более поздее время?',
          text: 'Да, наш бот умеет показывать расписание не только на сегодня и завтра. Вы можете построить запрос по данному формату: Расписание [на] [сегодня|завтра|через N дней/недель|день недели|дата]. Примеры использования:',
          list: [
            'Расписание',
            'Расписание на сегодня',
            'Расписание на завтра',
            'Расписание через 5 дней',
            'Расписание через 10 недель',
            'Расписание на вторник',
            'Расписание на 25.09',
          ]
        },
        {
          title: 'Можно ли использовать голосовые сообщения в вашем боте?',
          text: 'Да, наш бот умеет обрабатывать голосовые сообщения и отвечать на них. Запросы при этом следует строить точно так же, как описано в пункте выше.',
        },
        {
          title: 'Ваш бот есть только в "Название социальной сети"?',
          text: 'Вы можете получить список всех социальных сетей, в которых имеется наш бот, написав команаду "Ссылки".'
        },
        {
          title: 'Моего учебного заведения нет в боте, что делать?',
          text: 'К сожалению мы не можем добавить сразу все вузы, но вы можете нам помочь добавить вашу организацию оставив заявку на главной странице.',
        },
      ]
    },
    tech: {
      title: 'На чём построен Schedulty?',
    },
    joinus: {
      title: 'Присоединиться',
      text: 'Хотите иметь простой доступ к расписанию и для своего ВУЗа? Тогда вы можете присоединиться к нам, воспользовавшись формой ниже!',
      city: 'Город',
      univercity: 'ВУЗ',
      contact: 'Ваш email или ссылка на любую соц. сеть',
      button: 'Присоединиться',
      thanks: {
        title: 'Спасибо за обращение!',
        text: 'Мы ответим на вашу заявку как можно быстрее!'
      },
    },
    404: {
      text: 'Страница не найдена.',
      home: 'На главную.',
    }
  },
};

export default ru;
