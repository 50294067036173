import React, { useEffect, useState } from 'react';

import {
  AppBar,
  Button,
  Container,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import TranslateIcon from '@material-ui/icons/Translate';
import GitHubIcon from '@material-ui/icons/GitHub';

import { useTranslation } from 'react-i18next';

import Link from '../../molecules/Link';
import Drawer from '../../molecules/Drawer';

const useStyles = makeStyles(() => createStyles({
  title: {
    textDecoration: 'none',
    color: '#5777ba',
  },
  transition: {
    transition: 'all 0.3s',
  },
  container: {
    display: 'flex',
    maxWidth: '1320px',
  },
  headerScrolled: {
    background: 'rgba(255, 255, 255, 0.95)',
    boxShadow: '0px 2px 15px rgb(0 0 0 / 10%)',
  },
  h1: {
    fontSize: '30px',
    lineHeight: '1',
    fontWeight: 400,
    letterSpacing: '2px',
    fontFamily: '"Raleway", sans-serif',
    textAlign: 'unset'
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0 10px 30px',
    fontFamily: '"Raleway", sans-serif',
    fontWeight: 400,
    fontSize: '15px',
    minWidth: 'auto',
    padding: 0,
    color: '#47536e',
    whiteSpace: 'nowrap',
    transition: '0.3s',
    textDecoration: 'none',
    textTransform: 'none',
    '&:hover': {
      background: 'none',
      color: '#5777ba',
    },
  },
}));

export interface NavLink {
  href: string;
  text: string;
  isExternal: boolean;
}

const NavBar: React.FC = () => {
  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const [isPageScrolled, setIsPageScrolled] = useState(false);

  const theme = useTheme();
  const isDrawerEnable = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 10) {
        setIsPageScrolled(true);
      } else {
        setIsPageScrolled(false);
      }
    })
  },[])

  const changeLanguage = (e: React.SyntheticEvent) => {
    e.preventDefault();

    switch (i18n.language) {
      case 'en':
        localStorage.setItem('lang', 'ru')
        i18n.changeLanguage('ru');
        break;
      case 'ru':
        localStorage.setItem('lang', 'en')
        i18n.changeLanguage('en');
        break;
    }
  }

  const links: NavLink[] = t('navbar.links', { returnObjects: true }) as NavLink[];

  return (
    <AppBar color='transparent' elevation={0} className={`${classes.transition} ${isPageScrolled ? classes.headerScrolled : ''}`}>
      <Container component={Toolbar} className={classes.container} maxWidth={false}>
          <Typography className={classes.h1} variant="h1" sx={{ flexGrow: 1 }}>
            <Link href="#schedulty" className={classes.title} isExternal={false}>
              Schedulty
            </Link>
          </Typography>
          {isDrawerEnable
            ?
              <Drawer links={links} changeLanguage={changeLanguage} />
            :
              <>
                {links.map(link => (
                  <Link 
                    key={link.href + link.text}
                    href={link.href}
                    isExternal={link.isExternal}
                    className={classes.link}
                  >
                    {link.text}
                  </Link>
                ))}
                <Button className={classes.link} disableRipple={true} href='https://github.com/Schedulty' target='_blank'>
                  <GitHubIcon />
                </Button>
                <Button className={classes.link} disableRipple={true} onClick={changeLanguage}>
                  <TranslateIcon />
                </Button>
              </>
          }
      </Container>
    </AppBar>
  )
}

export default NavBar;
