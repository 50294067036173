import React from 'react'

import { 
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box 
} from '@material-ui/core';
import { createStyles, makeStyles, withStyles } from '@material-ui/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { useTranslation } from 'react-i18next';

import Container from '../../atoms/Container';

const Accordion = withStyles({
  root: {
    border: '1px solid #ddd',
    backgroundColor: '#fdfcff',
    width: '840px',
    marginTop: '16px',
    "@media (max-width: 991px)": {
      width: '100%',
    },
    '&$expanded': {
      margin: '16px 0 0 0',
    },
  },
  expanded: {},
})(MuiAccordion);

const useStyles = makeStyles(() => createStyles({
  accordionSummary: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  accordionDetails: {
    fontFamily: '"Open Sans", sans-serif',
    color: '#585a61',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5',
    whiteSpace: 'pre-line',
  },
  helpIcon: {
    color: '#b1c0df',
    marginRight: '8px',
    alignSelf: 'center',
  }
}));

interface FaqItem {
  title: string;
  text: string;
  list?: string[];
}

const FAQ : React.FC = () => {
  const classes = useStyles();
  
  const { t } = useTranslation();

  const faqItems: FaqItem[] = t('faq.list', { returnObjects: true }) as FaqItem[];

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <section id={t('navbar.links.0.href').substring(1)}>
      <Container>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <h1>
            {t('faq.title')}
          </h1>
          <h2>
            {t('faq.text')}
          </h2>
          {faqItems.map((faqItem, index) => (
            <Accordion 
              key={faqItem.title + faqItem.text}
              expanded={expanded === faqItem.title}
              onChange={handleChange(faqItem.title)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
              >
                <HelpOutlineIcon className={classes.helpIcon} />
                <Typography className={classes.accordionSummary}>
                  {faqItem.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.accordionDetails}>
                  {faqItem.text}
                </Typography>
                {faqItem.list 
                  &&
                  faqItem.list.map(el => (
                    <ul key={el}>
                      <li className={classes.accordionDetails}>
                        {el}
                      </li>
                    </ul>
                  ))
                }
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </section>
  )
}

export default FAQ;